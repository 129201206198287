import React from 'react'

// import PageHeader from '../components/PageHeader'
// import LazyImage from '../components/LazyImage'
// import Content from '../components/Content.js'
import './About.css'
import News from './News'

export default ({news}) => {
  return (
    <div className='About'>
     
     <div id="linea-verde">
    <section className="container">
        <section className="row" id="chi-siamo">
            <div className="col-lg-4 col-md-4">
                <div id="c-leader">
                    <h4>Leader nell'erogazione<br></br>di <span className="b-verde">servizi innovativi</span><br></br>per retailer italiani</h4>
                    <span id="c-leader-icona" className="b"></span>
                </div>
                
                <div id="c-ricerca">
                    <h4><span className="b-verde">Ricerchiamo</span> continuamente innovazione in ogni parte<br></br>del mondo per renderla<br></br>disponibile ai nostri clienti</h4>
                    <span id="c-ricerca-icona" className="b"></span>
                </div>
            </div>
            
            <div className="col-lg-4 col-md-4">
                <div id="c-prodotti">
                    <h4>Leader nella distribuzione di <span className="b-verde">prodotti innovativi</span> per l'automazione del negozio</h4>
                    <span id="c-prodotti-icona" className="b"></span>
                </div>
                <h2>Offriamo un servizio a cinque stelle</h2>
            </div>
            
            <div className="col-lg-4 col-md-4">
                <div id="c-rete">
                    <h4>Operiamo con una<br></br><span className="b-verde">rete di concessionari</span><br></br>su tutto il territorio</h4>
                    <span id="c-rete-icona" className="b"></span>
                </div>
                
                <div id="c-competitivi">
                    <h4>Aiutiamo il retailer a fare negozio<br></br>in modi innovativi introducendo risparmi significativi ed aiutandoli ad essere pi&uacute; <span className="b-verde">competitivi</span></h4>
                    <span id="c-competitivi-icona" className="b"></span>
                </div>
            </div>
        </section>
    </section>
</div>
    <News news={news}></News>

      {/* <PageHeader
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />
      <div className='section'>
        <div className='container'>
          <Content source={section1} />
        </div>
      </div>
      <div className='section'>
        <div className='container'>
          <Content source={section2} />
          <p>The image below is a {'<LazyImage />'}</p>
          <LazyImage src={featuredImage} alt='LazyImage' />
        </div>
      </div> */}
    </div>
  )
}
