/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { slugify } from '../util/url'

// import './Products.css'

const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=')
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : ''
          return params
        }, {})
    : {}
}

export default ({ products, location, categories }) => {
  // const { body, title, subtitle, featuredImage, address, phone, email } = fields
  const [page, setPage] = useState(0)
  const [filter, setFilter] = useState('')
  const [curlocation, setLocation] = useState('')

  useEffect(() => {
    let qs = getQueryStringParams(location.search)
    let curPage = 0
    if (qs.page !== undefined && qs.page !== '') curPage = parseInt(qs.page)
    setPage(curPage)
    if (qs.filter !== undefined && qs.filter !== '') {
      let matchedFilter = '';
      try{
        matchedFilter = categories.find(c => c.name === qs.filter).title
      }catch(e){
        console.warn('Filter not found ' + qs.filter)
      }
      setFilter(matchedFilter)
    }
    if (location.pathname.split('/').length === 3) {
      setLocation(location.pathname.split('/')[2])
    } else {
      setLocation('')
    }
    // return () => {
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="Products">
      {products.map((product, idx) => {
        const {
          title,
          brand,
          postFeaturedImage,
          excerpt,
          categories,
          content,
          status,
        } = product
        const path = slugify(`/product/${title}`)
        if (status.includes('Featured')) {
          return (
            <div className="verde2" key={idx}>
              <section className="container">
                <section className="row">
                  <div className="col-lg-12 col-md-12">
                    <div id="box-novita">
                      <div className="novita-testo">
                        <h6>Novità {title}</h6>
                        <p>{excerpt}</p>
                        <a href={path} className="btn-grigino">
                          Scopri
                        </a>
                      </div>
                      <img
                        src={postFeaturedImage}
                        alt={title}
                        width="436"
                        height="288"
                      />
                    </div>
                  </div>
                </section>
              </section>
            </div>
          )
        } else {
          return <div key={idx}/>
        }
      })}

      <div className="menu">
        <section className="container">
          <section className="row">
            <div className="col-lg-8 col-md-8">
              <ul id="menu-prodotti">
                <li>
                  <NavLink
                    activeClassName="menu-prodotti-focus"
                    exact={true}
                    onClick={() => {
                      setLocation('')
                      setFilter('')
                    }}
                    className="menu-prodotti-grigio"
                    to="/products"
                  >
                    Tutti<br></br>prodotti
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="menu-prodotti-focus"
                    className="menu-prodotti-grigio"
                    onClick={() => {
                      setLocation('pos')
                      setFilter('')
                    }}
                    to="/products/pos"
                  >
                    Soluzioni<br></br>cassiera
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="menu-prodotti-focus"
                    className="menu-prodotti-grigio"
                    onClick={() => {
                      setLocation('self')
                      setFilter('')
                    }}
                    to="/products/self"
                  >
                    soluzioni<br></br>self-selvice
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="menu-prodotti-focus"
                    className="menu-prodotti-grigio"
                    onClick={() => {
                      setLocation('pay')
                      setFilter('')
                    }}
                    to="/products/pay"
                  >
                    Soluzioni<br></br>pagamento
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-4">
              <div id="filtra-prodotti">
                <p>Filtra per:</p>
              </div>
            </div>
          </section>
        </section>
      </div>

      <section className="container">
        <section className="row">
          <div className="col-lg-8 col-md-8" id="elenco-prod">
            {products
              .filter(product => {
                let first = product.categories.some(
                  p => p.category === curlocation
                )
                if (curlocation === '') {
                  first = true
                }
                let second = product.categories.some(p => p.category === filter)
                if (filter === '') {
                  second = true
                }

                /* console.log(curlocation)
                console.log(first)
                console.log(filter)
                console.log(product.categories.some(p => p.category === filter)) */

                return first && second
              })
              .map((product, idx) => {
                // test.map(p => p.category === "pos")
                const {
                  title,
                  brand,
                  postFeaturedImage,
                  excerpt,
                  categories,
                  content,
                  status,
                } = product
                const path = slugify(`/product/${title}`)
                if (
                  idx >= page * 10 &&
                  idx < (page + 1) * 10 &&
                  !status.includes('Featured')
                ) {
                  return (
                    <div className="box-prod-fin" key={idx}>
                      <div className="box-prodotto-dentro">
                        <a href={path}>
                          <img
                            src={postFeaturedImage}
                            width="140"
                            height="140"
                            alt=""
                          />
                        </a>
                        <a href={path} className="prod-nome">
                          {title}
                        </a>
                        <p className="prod-marca">{brand}</p>
                        <p className="prod-def-fin">{excerpt}</p>

                        {/* <div className="colli">
                  <p>
                    <span>5</span> in magazzino
                  </p>
                </div> */}
                      </div>
                    </div>
                  )
                } else return <div key={idx}/>
              })}

            {/* <div className="box-prod-fin">
              <div className="box-prodotto-dentro">
                <a href="scheda.php">
                  <img src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/kt.jpg" width="140" height="140" alt="" />
                </a>
                <a href="scheda.php" className="prod-nome">
                  Paperino
                </a>
                <p className="prod-marca">Newland</p>
                <p className="prod-def-fin">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Quis, repellat, sint, earum, magnam molestiae recusandae iusto
                  vero illo maxime quam quas voluptatibus iusto vero illo maxime
                </p>

                <div className="colli">
                  <p>
                    <span>5</span> in magazzino
                  </p>
                </div>
              </div>
            </div>             */}
          </div>

          <div className="col-lg-4 col-md-4" id="filtri">
            <p>Categorie</p>
            <ul id="filtro-categorie">
              <li>
                <img
                  src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/i-pos.svg"
                  width="40"
                  height="40"
                  alt=""
                />
                <Link
                  onClick={() => {
                    setLocation('')
                    setFilter('terminali')
                  }}
                  to={{
                    pathname: '/products',
                    search: 'filter=terminali&page=0',
                  }}
                >
                  Terminali POS
                </Link>
              </li>
              <li>
                <img
                  src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/i-stamp.svg"
                  width="40"
                  height="40"
                  alt=""
                />
                <Link
                  onClick={() => {
                    setLocation('')
                    setFilter('stampanti fiscali')
                  }}
                  to={{
                    pathname: '/products',
                    search: 'filter=stampantifiscali&page=0',
                  }}
                >
                  Stampanti Fiscali
                </Link>
              </li>
              <li>
                <img
                  src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/i-scanner.svg"
                  width="40"
                  height="40"
                  alt=""
                />
                <Link
                  onClick={() => {
                    setLocation('')
                    setFilter('barcode scanner')
                  }}
                  to={{
                    pathname: '/products',
                    search: 'filter=bscanner&page=0',
                  }}
                >
                  Scanner Barcode
                </Link>
              </li>
              <li>
                <img
                  src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/i-display.svg"
                  width="40"
                  height="40"
                  alt=""
                />
                <Link
                  onClick={() => {
                    setLocation('')
                    setFilter('display')
                  }}
                  to={{
                    pathname: '/products',
                    search: 'filter=display&page=0',
                  }}
                >
                  Display
                </Link>
              </li>
              <li>
                <img
                  src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/i-tast.svg"
                  width="40"
                  height="40"
                  alt=""
                />
                <Link
                  onClick={() => {
                    setLocation('')
                    setFilter('keyboard')
                  }}
                  to={{
                    pathname: '/products',
                    search: 'filter=keyboard&page=0',
                  }}
                >
                  Tastiere
                </Link>
              </li>
              <li>
                <img
                  src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/i-self.svg"
                  width="40"
                  height="40"
                  alt=""
                />
                <Link
                  onClick={() => {
                    setLocation('')
                    setFilter('totem self')
                  }}
                  to={{
                    pathname: '/products',
                    search: 'filter=totem&page=0',
                  }}
                >
                  Totem self-service
                </Link>
              </li>
              <li>
                <img
                  src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/i-cassa.svg"
                  width="40"
                  height="40"
                  alt=""
                />
                <Link
                  onClick={() => {
                    setLocation('')
                    setFilter('casseforti')
                  }}
                  to={{
                    pathname: '/products',
                    search: 'filter=casseforti&page=0',
                  }}
                >
                  Casseforti
                </Link>
              </li>
              <li>
                <img
                  src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/i-cassetti.svg"
                  width="40"
                  height="40"
                  alt=""
                />
                <Link
                  onClick={() => {
                    setLocation('')
                    setFilter('cassetti')
                  }}
                  to={{
                    pathname: '/products',
                    search: 'filter=cassetti&page=0',
                  }}
                >
                  Cassetti portamonete
                </Link>
              </li>
              <li>
                <img
                  src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/i-furto.svg"
                  width="40"
                  height="40"
                  alt=""
                />
                <Link
                  onClick={() => {
                    setLocation('')
                    setFilter('dissuasori furto')
                  }}
                  to={{
                    pathname: '/products',
                    search: 'filter=bollard&page=0',
                  }}
                >
                  Dissuasori al furto
                </Link>
              </li>
              <li>
                <img
                  src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/i-app.svg"
                  width="40"
                  height="40"
                  alt=""
                />
                <Link
                  onClick={() => {
                    setLocation('')
                    setFilter('apps')
                  }}
                  to={{
                    pathname: '/products',
                    search: 'filter=apps&page=0',
                  }}
                >
                  Applicazioni
                </Link>
              </li>
              <li>
                <img
                  src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/i-conf.svg"
                  width="40"
                  height="40"
                  alt=""
                />
                <Link
                  onClick={() => {
                    setLocation('')
                    setFilter('configurazioni predefinite')
                  }}
                  to={{
                    pathname: '/products',
                    search: 'filter=config&page=0',
                  }}
                >
                  Configurazioni predefinite
                </Link>
              </li>
            </ul>

            {/* <p>Casa produttrice</p>
            <ul id="filtro-casa">
              <li>
                <a href="">Posiflex</a>
              </li>
              <li>
                <a href="">NCR</a>
              </li>
              <li>
                <a href="">Newland</a>
              </li>
            </ul> */}
          </div>
        </section>
        <section className="row" id="productPages">
          {products
            .filter(product => {
              let first = product.categories.some(
                p => p.category === curlocation
              )
              if (curlocation === '') {
                first = true
              }
              let second = product.categories.some(p => p.category === filter)
              if (filter === '') {
                second = true
              }
              return first && second
            })
            .map((product, idx) => {
              if (idx % 10 === 0) {
                return (
                  <Link
                    key={idx}
                    onClick={() => {
                      // setLocation('pos')
                      // setFilter('stampantifiscali')
                      setPage(idx / 10)
                    }}
                    to={{
                      pathname: '/products/' + curlocation,
                      search:
                        (filter && 'filter=' + filter + '&') +
                        'page=' +
                        idx / 10,
                    }}
                  >
                    {idx / 10 + 1}
                  </Link>
                )
              } else
                return (
                  <Link
                    key={idx}
                    to={{
                      pathname: '/products',
                    }}
                  />
                )
            })}
        </section>
      </section>
    </div>
  )
}
