/* eslint-disable no-unused-vars */
import React from 'react'
import { Link } from 'react-router-dom'

import { slugify } from '../util/url'
import './Home.css'
import News from './News'

export default ({ featured, products, news }) => {
  let featuredElement = <></>
  if (featured) {
    const {
      title: ftitle,
      brand,
      postFeaturedImage,
      excerpt,
      categories,
      content,
      status,
    } = featured
    const path = slugify(`/product/${ftitle}`)
    featuredElement = (
      <div className="col-lg-6 col-md-12 promozione">
        <div className="posiflex">
          <h6>Novità {ftitle}</h6>
          <p>{excerpt}</p>
          <img src={postFeaturedImage} alt={ftitle} width="436" height="288" />
          <a href={path} className="btn-grigino">
            Scopri
          </a>
        </div>
      </div>
    )
  }

  return (
    <main className="Home">
      <br></br>
      <br></br>
      <br></br>
      <div className="verde2">
        <section className="container">
          <div className="row">
            {/* eslint-disable-next-line jsx-a11y/heading-has-content*/}
            <h2 id="badge"></h2>
            <div id="frasi">
              <div className="frase col-lg-offset-4 col-lg-4 col-md-offset-4 col-md-4">
                <span id="b-leader" className="b"></span>
                <h4>
                  Leader nell'erogazione di{' '}
                  <strong className="frase-nera">servizi innovativi</strong> per
                  retailer italiani
                </h4>
              </div>
              <div className="frase col-lg-4 col-md-4">
                <span id="b-rete" className="b"></span>
                <h4>
                  Operiamo con una{' '}
                  <strong className="frase-nera">rete di concessionari</strong>{' '}
                  su tutto il territorio
                </h4>
              </div>
              <div className="frase col-lg-4 col-md-4">
                <span id="b-prodotti" className="b"></span>
                <h4>
                  Leader nella distribuzione di{' '}
                  <strong className="frase-nera">prodotti innovativi</strong>{' '}
                  per l'automazione del negozio
                </h4>
              </div>
              <div className="frase col-lg-4 col-md-4">
                <span id="b-ricerca" className="b"></span>
                <h4>
                  <strong className="frase-nera">Ricerchiamo</strong>{' '}
                  continuamente innovazione in ogni parte del mondo per renderla
                  disponibile ai nostri clienti
                </h4>
              </div>
              <div className="frase col-lg-4 col-md-4">
                <span id="b-competitivi" className="b"></span>
                <h4>
                  Aiutiamo il retailer a fare negozio in modi innovativi
                  introducendo risparmi significativi ed aiutandoli ad essere
                  pi&uacute; <strong className="frase-nera">competitivi</strong>
                </h4>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="container">
        <section className="row">
          <div id="servizi" className="col-lg-12 col-md-12"></div>

          <div className="col-lg-5 col-md-5">
            <div className="s-gestione">
              <h5>Gestione del negozio</h5>
              <div className="blocco">
                <p>Recupero di efficienza e produttivit&agrave;</p>
                <ul>
                  <li>Automazione barriera cassa</li>
                  <li>
                    Studio predittivo necessit&agrave; personale alle casse
                  </li>
                  <li>altro..</li>
                </ul>

                <Link className="btn-servizio" to="/services/">
                  Scopri di pi&ugrave;{' '}
                  <span className="glyphicon glyphicon-chevron-right"></span>
                </Link>
              </div>
            </div>

            <div className="s-tesoro">
              <h5>Tesoreria</h5>
              <div className="blocco">
                <p>Ottimizzazione rapporto con gestori di pagamento</p>
                <p>Gestione del cash e relazione con le banche</p>
                <Link className="btn-servizio" to="/services/">
                  Scopri di pi&ugrave;{' '}
                  <span className="glyphicon glyphicon-chevron-right"></span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-2">
            <span id="s-gestione" className="s-icona"></span>
            <span id="s-vendita" className="s-icona"></span>
            <span id="s-tesoro" className="s-icona"></span>
            <span id="s-customer" className="s-icona"></span>
          </div>
          <div className="col-lg-5 col-md-5">
            <div className="s-vendita">
              <h5>Vendita di prodotti e servizi</h5>
              <div className="blocco">
                <p>
                  Vendita ricariche o Gift Cards, gestione buoni sconto o buoni
                  pasto, gestione delle utenze
                </p>
                <ul>
                  <li>Chioschi informativi</li>
                  <li>altro..</li>
                </ul>
                <Link className="btn-servizio" to="/services/">
                  Scopri di pi&ugrave;{' '}
                  <span className="glyphicon glyphicon-chevron-right"></span>
                </Link>
              </div>
            </div>

            <div className="s-customer">
              <h5>Customer relationship</h5>
              <div className="blocco">
                <p>Riqualificazione figure professionali</p>
                <p>Gestione buoni sconto e carte fedeltà</p>
                <ul>
                  <li>Smartphone Apps</li>
                  <li>altro..</li>
                </ul>
                <Link className="btn-servizio" to="/services/">
                  Scopri di pi&ugrave;{' '}
                  <span className="glyphicon glyphicon-chevron-right"></span>
                </Link>
              </div>
            </div>
            {/* </div> */}
          </div>
        </section>
        <div id="prodotti" className="col-lg-12 col-md-12"></div>
      </section>

      <div className="grigino">
        <section className="container">
          <section className="row">
            <div className={featured && "col-lg-6 col-md-12 prodotti" || "col-lg-12 col-md-12 prodotti"}>
              {products.map((product, index) => {
                const {
                  title,
                  brand,
                  postFeaturedImage,
                  excerpt,
                  categories,
                  content,
                  status,
                } = product
                const pathLoc = slugify(`/product/${title}`)
                return (
                  <div className="box-prodotto" key={index}>
                    <div className="box-prodotto-dentro">
                      <img src={postFeaturedImage} alt="" />
                      <a href={pathLoc} className="prod-nome">
                        {title}
                      </a>
                      <p className="prod-marca">{brand}</p>
                      <p className="prod-def">{excerpt}</p>
                    </div>
                  </div>
                )
              })}
              {/* <div className="box-prodotto">
                <div className="box-prodotto-dentro">
                  <img src="" alt="" />
                  <a href="scheda.php" className="prod-nome">
                    Paperino
                  </a>
                  <p className="prod-marca">Newland</p>
                  <p className="prod-def">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Quis, repellat, sint, earum, magnam molestiae recusandae
                    iusto vero illo maxime quam quas voluptatibus iusto vero
                    illo maxime quam quas voluptatibus
                  </p>
                </div>
              </div>         */}

              <div className="btn-centro">
                <Link className="btn-verdino" to="/products/">
                  Guarda tutti i prodotti
                </Link>
              </div>
            </div>
            {featuredElement}
            {/* <div className="col-lg-6 col-md-12 promozione">
              <div className="posiflex">
                <h6>Novità {ftitle}</h6>
                <p>{excerpt}</p>
                <img
                  src={postFeaturedImage}
                  alt={ftitle}
                  width="436"
                  height="288"
                />
                <a href={path} className="btn-grigino">
                  Scopri
                </a>
              </div>
            </div> */}
          </section>
        </section>
      </div>

      <News news={news}></News>

      {/* <PageHeader
        large
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />

      <div className='section'>
        <div className='container'>
          <Content source={body} />
        </div>
      </div> */}
    </main>
  )
}
