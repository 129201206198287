import React from 'react'
// import './Resellers.css'

export default () => {
  return (
    <div className="Resellers">
      <section className="container">
        <section id="squadra" className="row">
          <div className="col-sm-6">
            <h4>Vuoi unirti alla squadra?</h4>
            <p>
              Siamo sempre alla ricerca di forze nuove, nuovi partner che ci
              aiutino ad aggredire un mercato sempre pi&ugrave; competitivo.
              <br></br>
              Se volete entrare a far parte della nostra squadra inviate pure
              una mail all'indirizzo:{' '}
              <span className="highmail">info@infostorenet.it</span>
            </p>
          </div>
          <div className="col-sm-offset-1 col-sm-5">
            <h4>I nostri partner</h4>
            <img
              src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/riv-infomat.jpg"
              alt="Partner di Infostore: Infomat"
            />
            <img
              src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/gabtamagnini.jpg"
              alt="Partner di Infostore: CreatorMail Italia"
            />
          </div>
        </section>
      </section>

      <hr className="line-verde"></hr>

      <div className="grigino-head-line">
        <section className="container">
          <section id="rivenditori" className="row">
            <div className="col-sm-12">
              <h6 className="tit-verde">Tutti i Rivenditori</h6>
            </div>
            <div className="col-sm-4">
              <p className="citta">Lombardia</p>
              <div className="box-riv">
                <h3>Work System srl</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  Via Roma, 108/G<br></br>
                  20060 Cassina de' Pecchi Milano
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  +39 02 951 7554
                </p>
              </div>
              <div className="box-riv">
                <h3>supertronic</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  Via S. Giovanni Battista De La Salle 4<br></br>
                  Milano, 20132
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  02 89050392
                </p>
                <p>
                  <span>sito web</span>
                  <br></br>
                  www.supertronic.it
                </p>
              </div>
              <div className="box-riv">
                <h3>varc srl</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  Via Giuseppe Meda 36<br></br>
                  Milano<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  02 89050392
                </p>
              </div>
              <div className="box-riv">
                <h3>retex spa</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  Centro direzionale Milanofiori<br></br>
                  Strada 4, Ingresso A5<br></br>
                  20090 Assago MI<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  02 89050392
                </p>
              </div>

              <p className="citta">Umbria</p>
              <div className="box-riv">
                <h3>leonello lunghi srl</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  viale dei Pini<br></br>
                  06081 Assisi Perugia<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  075 8098200
                </p>
              </div>

              <p className="citta">Marche</p>
              <div className="box-riv">
                <h3>ht &amp; s</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via delle Palombare, 55<br></br>
                  60127 Ancona<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  071 9985827
                </p>
              </div>
              <div className="box-riv">
                <h3>meeting informatica</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via Giovanni Falcone, 3<br></br>
                  62010 Pollenza MC<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  0733 202558
                </p>
              </div>

              <p className="citta">Puglia</p>
              <div className="box-riv">
                <h3>professional service srl</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via Oslavia, 43<br></br>
                  73100 Lecce<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  0832 231105
                </p>
              </div>

              <p className="citta">Calabria</p>
              <div className="box-riv">
                <h3>Bruzia Informatica S.A.S. Di Filice Paola &amp; C.</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  Contrada Cutura<br></br>
                  87036 Rende Cosenza<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  098 4483279
                </p>
              </div>
              <div className="box-riv">
                <h3>elpi system di rocca filippo giuseppe</h3>
                <p>
                  <span>tel</span>
                  <br></br>
                  0968 438156
                </p>
              </div>
            </div>

            <div className="col-sm-4">
              <p className="citta">Liguria</p>
              <div className="box-riv">
                <h3>Gam Office Service srl</h3>
                <p>
                  <span>tel</span>
                  <br></br>
                  010 8938481
                </p>
              </div>
              <div className="box-riv">
                <h3>R &amp; B</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via Vecchia Piemonte, 64<br></br>
                  18100 Imperia IM<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  0183 766168
                </p>
              </div>

              <p className="citta">Veneto</p>
              <div className="box-riv">
                <h3>berto paolo</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via Igna, 21<br></br>
                  36030 Zugliano VI<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  044 5360162
                </p>
              </div>
              <div className="box-riv">
                <h3>serenissima informatica spa</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via della Croce Rossa, 5<br></br>
                  35129 Padova<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  049 829 1111
                </p>
                <p>
                  <span>sito web</span>
                  <br></br>
                  www.serinf.it
                </p>
              </div>

              <p className="citta">Lazio</p>
              <div className="box-riv">
                <h3>hi.tec group srl</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via della Bufalotta, 374<br></br>
                  Pal. G Tecnocenter Talenti <br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  06 87130149
                </p>
                <p>
                  <span>sito web</span>
                  <br></br>
                  www.htg.name
                </p>
              </div>
              <div className="box-riv">
                <h3>it-avantec</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via Madonna di Ponza<br></br>
                  SNC, FORMIA<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  071 9985827
                </p>
              </div>

              <p className="citta">Campania</p>
              <div className="box-riv">
                <h3>sistemi pos</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via delle Betulle, Parco la Pernice<br></br>
                  81100 Caserta CE<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  0823 342598
                </p>
              </div>
              <div className="box-riv">
                <h3>computers service srl</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via Larga, area PIP, lotto 1.13<br></br>
                  81038 -Trentola Ducenta CE<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  081 8120044
                </p>
              </div>
              <div className="box-riv">
                <h3>easy media solutions srl</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via dei Calzolai, 3<br></br>
                  80144 Napoli<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  081 8048631
                </p>
              </div>
              <div className="box-riv">
                <h3>
                  Touch &amp; Print Sas Di Dario Enrico Ed Acanfora Salvatore
                  &amp; C.
                </h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via Giacinto Gigante, 154<br></br>
                  Napoli<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  081 2292123
                </p>
              </div>
            </div>

            <div className="col-sm-4">
              <p className="citta">Emilia Romagna</p>
              <div className="box-riv">
                <h3>gab tamagnini</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via B. Disraeli, 17<br></br>
                  42124 Reggio nell'Emilia RE<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  0522 383222
                </p>
              </div>
              <div className="box-riv">
                <h3>mercurio sistemi srl</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  strada Provinciale Nord, 213<br></br>
                  42017 Novellara Reggio nell'Emilia<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  +39 0522 653690
                </p>
                <p>
                  <span>sito web</span>
                  <br></br>
                  www.mercuriosistemi.it
                </p>
              </div>

              <p className="citta">Toscana</p>
              <div className="box-riv">
                <h3>ds distribution service srl</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via del Feo, 1<br></br>
                  Pisa<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  050 809801
                </p>
              </div>
              <div className="box-riv">
                <h3>auriga</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via Ippolito Rosellini, 10<br></br>
                  50127 Firenze<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  055 4378361
                </p>
                <p>
                  <span>sito web</span>
                  <br></br>
                  www.aurigasrl.eu
                </p>
              </div>
              <div className="box-riv">
                <h3>erreuno</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via Leonardo da Vinci, 11<br></br>
                  53048 Siralunga SI<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  0577 679846
                </p>
              </div>

              <p className="citta">Sicilia</p>
              <div className="box-riv">
                <h3>v.d.f. system pos</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via Macello, 32<br></br>
                  95123 Catania CT<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  095 7441347
                </p>
              </div>
              <div className="box-riv">
                <h3>Sferas Snc Di Assenzo Giuseppe &amp; C.</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  Contrada Durruelli<br></br>
                  Porto Empedocle, AG 92014<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  0922 601308
                </p>
              </div>
              <div className="box-riv">
                <h3>vitamia srl</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via della Resurrezione, 11<br></br>
                  90146 Palermo<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  091 5072543
                </p>
              </div>

              <p className="citta">Sardegna</p>
              <div className="box-riv">
                <h3>data system srl</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via Santa Gilla, 51<br></br>
                  09122 Cagliari<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  070 288081
                </p>
                <p>
                  <span>sito web</span>
                  <br></br>
                  www.datasystempos.it
                </p>
              </div>
              <div className="box-riv">
                <h3>Adema srl</h3>
                <p>
                  <span>indirizzo</span>
                  <br></br>
                  via Capotesta, 11<br></br>
                  07026 Olbia OT<br></br>
                </p>
                <p>
                  <span>tel</span>
                  <br></br>
                  +39 0789 25176
                </p>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>
  )
}
