import React from 'react'
// import { MapPin, Smartphone, Mail } from 'react-feather'

// import EnquiryFormSimpleAjax from '../components/EnquiryFormSimpleAjax'
// import Content from '../components/Content'
import './Contact.css'

export default () => {
  return (
    <div id="contacts">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-md-offset-1 col-md-4 col-sm-5 indirizzo">
                <h5>indirizzo</h5>
                <p>Via Desenzano 6, 20146 MI</p>
                <h5>e-mail</h5>
                <p>info@infostorenet.it</p>
                <h5>tel</h5>
                <p>02 89073955</p>
                <h5>fax</h5>
                <p>02 89073967</p>
              </div>
              <div className="nomi  col-sm-7 mail">
                <h5>
                  Claudio Benvenuti (<span className="italic">Presidente</span>)
                </h5>
                <p>claudio.benvenuti@infostorenet.it</p>
                <h5>
                  Alessandro Santalucia (
                  <span className="italic">Amministratore Delegato</span>)
                </h5>
                <p>
                  alessandro.santalucia@infostorenet.it <br />
                  +39 348-8819832
                </p>
                <h5>
                  Massimo Lonati (
                  <span className="italic">Amministrazione</span>)
                </h5>
                <p>massimo.lonati@infostorenet.it</p>
                <h5>
                  Barbara Scotece (Supporto &nbsp;
                  <span className="italic">Commerciale</span>)
                </h5>
                <p>barbara.scotece@infostorenet.it</p>
                <h5>
                  Paolo Russo (
                  <span className="italic">
                    Dir. Commerciale, soluzioni negozio
                  </span>
                  )
                </h5>
                <p>
                  paolo.russo@infostorenet.it <br />
                  +39 3930387544
                </p>
                <h5>
                  Laura Santalucia (
                  <span className="italic">Web and Graphic designer</span>)
                </h5>
                <p>laura.santalucia@infostorenet.it</p>
                <h5>
                  Luca Santalucia (<span className="italic">Web Master</span>)
                </h5>
                <p>luca.santalucia@infostorenet.it</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    /* <PageHeader
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />

      <div className="section Contact--Section1">
        <div className="container Contact--Section1--Container">
          <div>
            <Content source={body} />

            <div className="Contact--Details">
              {address && (
                <a
                  className="Contact--Details--Item"
                  href={`https://www.google.com.au/maps/search/${encodeURI(
                    address
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MapPin /> {address}
                </a>
              )}
              {phone && (
                <a className="Contact--Details--Item" href={`tel:${phone}`}>
                  <Smartphone /> {phone}
                </a>
              )}
              {email && (
                <a className="Contact--Details--Item" href={`mailto:${email}`}>
                  <Mail /> {email}
                </a>
              )}
            </div>
          </div>

          <div>
            <EnquiryFormSimpleAjax name="Simple Form Ajax" />
          </div>
        </div>
      </div> */
  )
}
