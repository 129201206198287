import React from 'react'
import { Link } from 'react-router-dom'

import Logo from './Logo'
// import NavLink from './NavLink'
import './Nav.css'

export default ({ handlePopupOpen }) => (
  <div className="verde">
    <section className="container">
      <header className="row">
        <h1 className="col-lg-2 col-md-2" style={{ marginTop: '20px' }}>
          <Logo />
        </h1>
        <nav className="col-lg-10 col-md-10">
          <div id="nav">
            <ul>
              <li className="nav-line">
                <Link to="/">
                  <span className="glyphicon glyphicon-home"></span>
                </Link>
              </li>
              <li className="nav-line">
                <Link to="/about/">Chi Siamo</Link>
              </li>
              <li className="nav-line">
                <div className="dropdown">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                  <a
                    id="m-prodotti"
                    className="nav-dropdown"
                    data-toggle="dropdown"
                  >
                    Prodotti <span className="caret"></span>
                  </a>
                  <ul
                    className="dropdown-menu"
                    role="menu"
                    aria-labelledby="dropdownMenu1"
                  >
                    <li role="presentation">
                      <Link to="/products/">Tutti i prodotti</Link>
                    </li>
                    <li role="presentation" className="divider"></li>
                    <li role="presentation">
                      <Link to="/products/pos/">Soluzioni cassiera</Link>
                    </li>
                    <li role="presentation">
                      <Link to="/products/self/">Soluzioni self-service</Link>
                    </li>
                    <li role="presentation">
                      <Link to="/products/pay/">Soluzioni pagamento</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-line">
                <Link to="/services/">Tutti i Servizi</Link>
                {/* <div className="dropdown">
                  <a
                    id="m-servizi"
                    className="nav-dropdown"
                    data-toggle="dropdown"
                  >
                    Servizi <span className="caret"></span>
                  </a>
                  <ul
                    className="dropdown-menu"
                    role="menu"
                    aria-labelledby="dropdownMenu1"
                  >
                    <li role="presentation">
                      <Link to="/services/" >
                        Tutti i Servizi
                      </Link>
                    </li>
                    <li role="presentation" className="divider"></li>
                    <li role="presentation">
                      <Link to="/services/shop/" >
                        Gestione del negozio
                      </Link>
                    </li>
                    <li role="presentation">
                      <Link to="/services/sales/" >
                        Vendita di prodotti e servizi
                      </Link>
                    </li>
                    <li role="presentation">
                      <Link to="/services/moneymanagement/" >
                        Tesoreria
                      </Link>
                    </li>
                    <li role="presentation">
                      <Link to="/services/relations/" >
                        Customer relationship
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </li>
              <li className="nav-line">
                <Link to="/resellers/">Rivenditori</Link>
              </li>
              <li className="nav-line">
                <Link to="/contact/">Contatti</Link>
              </li>
              <li className="nav-line">
                <Link to="/privacy/">Privacy</Link>
              </li>
              {/* <li className="nav-line">
                <a href="#">Forum</a>
              </li> */}
            </ul>
          </div>
        </nav>
      </header>
    </section>
  </div>
  /* <nav className='Nav'>
    <div className='Nav--Container container'>
      <Link to='/'>
        <Logo />
      </Link>
      <Link to='/' >
        Home
      </Link>
      <Link to='/about/' >
        About
      </Link>
      <Link to='/blog/' >
        Blog
      </Link>
      <Link to='/contact/' >
        Contact
      </Link>
    </div>
  </nav> */
)
