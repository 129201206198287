import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

export default ({ globalSettings, socialSettings, Links }) => (
   <div className="verdone">
        <footer className="container">
            <section className="row">
                <div className="col-lg-3 col-md-3">
                    <h6>Infostore Servizi</h6>
                    <ul>
                        <li><Link to="/about/" >Chi Siamo</Link></li>
                        <li><Link to="/products/" >Prodotti</Link></li>
                        <li><Link to="/services/" >Servizi</Link></li>
                        <li><Link to="/resellers/" >Rivenditori</Link></li>
                        <li><Link to="/contact/" >Contatti</Link></li>
                        <li><Link to="/privacy/" >Privacy</Link></li>
                    </ul>
                </div>
                <div className="col-lg-3 col-md-3">
                    <h6>Prodotti e Servizi</h6>
                    <ul>
                        <li><Link to="/products/pos/" >Soluzione per la cassiera</Link></li>
                        <li><Link to="/products/self/" >Soluzioni self-service</Link></li>
                        <li><Link to="/products/pay/" >Soluzioni per il pagamento</Link></li>
                        <li><Link to="/services/" >Gestione negozio</Link></li>
                        <li><Link to="/services/" >Vendita prodotti e servizi</Link></li>
                        <li><Link to="/services/" >Tesoreria</Link></li>
                        <li><Link to="/services/" >Customer relationship</Link></li>
                    </ul>
                </div>
                <div className="col-lg-6 col-md-6">
                    {/* <div id="newsletter">
                        <h6>Newsletter<br></br><span className="sottotitolo">Resta sempre aggiornato sulle nostre offerte!</span></h6>
                        <form action="">
                            <input className="fuffa" type="text" value="Inserisci la tua email" title="Inserisci la tua email">
                            <button type="submit" className="verde2"><span className="glyphicon glyphicon-chevron-right"></span></button>
                        </form>
                    </div> */}
                    <div id="contatti">
                        <h6>Contatti</h6>
                        <p>Tel: 02 89073955<br></br>Mail: info@infostorenet.it</p>
                    </div>
                </div>
                
                <div className="col-lg-12 col-md-12">
                    <div id="partner">
                        <h6>Partner</h6>
                        <img src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/partner1.png" alt="" width="165" height="43"/>
                        <img src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/partner2.png" alt="" width="150" height="43"/>
                        <img src="https://res.cloudinary.com/dlg6vencu/image/upload/v1583736106/partner3.png" alt="" width="116" height="43"/>
                    </div>
                    
                    <div id="legge">
                        <p>Dich. di legge 88/2009 &#124; Copyright Infostore servizi s.r.l.</p>
                    </div>
                </div>
            </section>
        </footer>
    </div>
)
